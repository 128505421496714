import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EventCard from "../components/EventCard";
import About from "./About";
import CountdownTimer from "../components/CountdownTimer";
import Gallery from "./Gallery";
import Contact from "./Contact";
import Faq from "./Faq"

import prodigy_logo from "../assets/prodigy_logo.png";

const Home = () => {
  const targetDate = new Date("2024-01-06T00:00:00").getTime();
  return (
    <div className="bg-gray-900">
      <Navbar />
      <div class="bg-gray-900 mb-3"></div>
      <section class="bg-gray-900">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              Prodigy
            </h1>
            <p class="text-justify max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-xl text-gray-400 ">
              Prodigy is a state-level technical event conducted for school
              students of grades 9-12. The objective of the event is to promote
              the awareness of computing and technology amongst students and
              ignite young minds towards the pursuit of computer science. By
              giving the students an insight into and the opportunity to
              experiment with the latest trends and technologies in the field,
              the event aims to provide a platform for students to explore their
              latent talent and encourages them to think beyond their textbooks.
              Prodigy is an annual technical event conducted by ACM-CEG, Anna
              University which comprises of interactive workshops and tech
              events designed to spark an interest in Computer Science among
              school students.<br></br> • Prodigy helps students get a clear
              vision of the current trends in Computer Science field and develop
              a passion for it. <br></br>• Certificates and exciting prizes will
              be provided.<br></br> • All the events are free and open to the
              students of Class 9-12.
            </p>
            <p class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4  focus:ring-gray-800">
              Register
              <svg
                class="w-5 h-5 ml-2 -mr-1 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </p>
            <a
              href="https://forms.gle/jSmPNEG1QZtqSzCM8"
              target="_blank"
              rel="noreferrer"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800 mx-2"
            >
              Schools
            </a>
            <a
              href="https://forms.gle/3bYJa81BHozMr6dSA"
              target="_blank"
              rel="noreferrer"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800"
            >
              Students
            </a>
          </div>
          <div class=" lg:mt-0 lg:col-span-5 flex justify-center items-center mt-8">
            <img src={prodigy_logo} alt="mockup" />
          </div>
        </div>
      </section>
      <CountdownTimer targetDate={targetDate} />
      <EventCard />
      <About />
      <Gallery />
      <Contact />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
