import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import '../src/index.css'

const App = () => {

  return(
    <>
      <Router>
        <AllRoutes/>
      </Router>
    </>
  )
}

const AllRoutes = () => {
  return(
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="*" element={<h1>404: Not Found</h1>} />
      </Routes>
  )
}

export default App;
