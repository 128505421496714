import ACM_LOGO from "../assets/acm_logo.png";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";

const Footer = () => {
  return (
    <div class="bg-gray-900">
      <footer class="rounded-md shadow bg-gray-800">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <a
              href="https://auceg.acm.org/"
              class="flex items-center mb-4 sm:mb-0"
            >
              <img src={ACM_LOGO} class="h-12 mr-3" alt="ACM_CEG Logo" />
            </a>
            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium  sm:mb-0 text-gray-400">
              <li>
                <a href="/#about" class="mr-4 hover:underline md:mr-6 ">
                  About
                </a>
              </li>
              <li>
                <a href="/#contact" class="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <hr class="my-6 sm:mx-auto border-gray-700 lg:my-8" />
          <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm sm:text-center text-gray-400">
              © 2023{" "}
              <a href="https://auceg.acm.org/" class="hover:underline">
                ACM-CEG™
              </a>
              . All Rights Reserved.
            </span>
            <div class="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
              <a href="https://www.instagram.com/acmceg/" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-white">
                <AiOutlineInstagram size={24} />
                <span className="sr-only">Instagram page</span>
              </a>
              <a href="https://www.linkedin.com/company/acm-student-chapter-of-ceg/mycompany/" target="_blank" rel="noreferrer" className="text-gray-500  hover:text-white">
                <AiFillLinkedin size={24} />
                <span className="sr-only">LinkedIn page</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
