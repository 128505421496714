import React from "react";
import img1 from "../assets/events/bc.png";
import img2 from "../assets/events/gd.png";
import img3 from "../assets/events/ama.png";
import img4 from "../assets/events/ff.png";
import img5 from "../assets/events/ide.png";
import img6 from "../assets/events/sk.png";
import img7 from "../assets/events/mat.png";
import img8 from "../assets/events/quiz.png";
import "../css/EventCard.css";

const EventCard = () => {
  return (
    <div className=" bg-gray-900" id="events">
      <h1 className="text-3xl font-bold text-center text-white pb-10">
        Events
      </h1>
      <div className="flex justify-center items-center p-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* First Row */}
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img class="mx-auto rounded-t-lg h-40 w-auto" src={img1} alt="" />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Coding Cauldron
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Want to showcase your coding skills as a team of 2? Here is the
                chance for you…<br></br>
                Coding Cauldron is an event conducted especially for school
                students which tests the coding skills along with logical and
                analytical understanding of the approach taken to solve.
                Overcome all the hindrances and come out with flying colors by
                solving the problems with minimum time. <br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: <br></br>Minimum - 1 member <br></br>Maximum - 2 members
                </p>
              </p>
            </div>
          </div>
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img class="mx-auto rounded-t-lg h-40 w-auto" src={img2} alt="" />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Ta(e)lk it out!
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Join the ultimate intellectual showdown and flaunt your
                oratorical skills! In this exhilarating panel discussion based
                on topics relating to our generation's love for all things tech,
                students from 9th/10th and 11th/12th grades will compete in a
                battle of wits. Be as creative as you can and share your raw
                thoughts, uninhibited! Are you ready to unleash your inner
                genius? Sign up and let your ideas soar!<br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: 1 member (Fixed)
                </p>
              </p>
            </div>
          </div>
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img class="mx-auto rounded-t-lg h-40 w-auto" src={img3} alt="" />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                AMA
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Thinking about a career in computer science? Do programming
                skills play a role in getting into CS? Not sure about what to
                pursue next? These doubts lead to confusion, don't they? You're
                in luck because the Ask Me Anything (AMA) session is here for
                you! Come and interact with our CEG students to get all your
                doubts clarified!
              </p>
              <p class="text-center items-center justify-center font-bold">
                  <a
                    href="https://forms.gle/ta9yp9NuV5zS6bq17"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800 mx-2 mt-4"
                  >
                    Add Query
                  </a>
                </p>
            </div>
          </div>

          {/* Second Row */}
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img class="mx-auto rounded-t-lg h-40 w-auto" src={img4} alt="" />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Family Feud
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Do you have what it takes to guess the crowd's favourites? Join
                our Family Feud event and put your skills to test on topics like
                Food, Technology, Books, Movies, Lifestyle, and more! Can your
                team guess the most popular answers to survey questions and win
                the title of Family Feud Champions? Find out in this thrilling
                and intense competition where every guess counts! Don't miss out
                on the fun, be a part of the excitement, and aim for the highest
                points to seize the victory! Join us for an unforgettable
                experience!<br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: 5 members (Fixed)
                </p>
                <p class="text-center items-center justify-center font-bold">
                  <a
                    href="https://forms.gle/tBAuHdBeqKdnBTVc9"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800 mx-2 mt-4"
                  >
                    Register
                  </a>
                </p>
              </p>
            </div>
          </div>
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img
              class="mx-auto rounded-t-lg h-40 w-auto pt-2"
              src={img5}
              alt=""
            />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Ideathon
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Unleash your inner innovator! Join us at Ideathon and be the
                driving force behind the next big tech idea. An electrifying
                team-based event specially designed for the bright student
                minds. Here's your chance to team up, spot real-life problems,
                and create tech-powered solutions.
                You can form teams of maximum three people. Participants have to
                take a real life problem that they want to impact, and then come
                up with a creative idea to solve the same!<br></br>
                Get your thinking hats on, unleash your creativity, and embrace
                the spirit of innovation to make the world a better place!
                <br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: <br></br>Minimum - 1 member <br></br>Maximum - 3 members
                </p>
                <p class="text-center items-center justify-center font-bold">
                  <a
                    href="https://forms.gle/ytFcwAVMZLuVA4UT8"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800 mx-2 mt-4"
                  >
                    Register
                  </a>
                </p>
              </p>
            </div>
          </div>
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img class="mx-auto rounded-t-lg h-40 w-auto" src={img6} alt="" />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Sketch Sprint
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Are u a creative artist? This call's for u.
                Unleash your creativity and quick thinking in Sketch sprint -
                where art meets intellect! Grab your team of four and embark on
                a drawing relay adventure. Decode science mysteries or unravel
                CS fundamentals, all against the ticking clock. Be the first to
                sketch and guess your way to victory - it's a drawing duel that
                will test your artistic flair and mental agility. In Sketch
                sprint, every stroke tells a story, and every guess takes you
                closer to triumph. Ready, set, draw your path to glory!<br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: 2 members (Fixed)
                </p>
                {/* <p class="text-center items-center justify-center font-bold">
                  <a
                    href="https://forms.gle/oyvVmLk6NXPBYqhx8"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800 mx-2 mt-4"
                  >
                    Register
                  </a>
                </p> */}
              </p>
            </div>
          </div>

          {/* Third Row */}
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img class="mx-auto rounded-t-lg h-40 w-auto" src={img7} alt="" />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Number ninja showdown
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Are you ready to embark on a journey of numbers, logic, and
                problem-solving? Welcome to the Number ninja showdown, Math
                Aptitude Challenge, an intellectually stimulating event that
                will ignite your passion for mathematics. This is an excellent
                opportunity to put your math skills to the test, learn from
                others, and have a great time. We look forward to seeing you at
                the Number ninja showdown Quiz Challenge!<br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: 2 members (Fixed)
                </p>
              </p>
            </div>
          </div>
          <div class="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700">
            <img
              class="mx-auto rounded-t-lg h-40 w-auto pt-2"
              src={img8}
              alt=""
            />
            <div class="p-5">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                Geek out trivia
              </h5>
              <p class="mb-3 font-normal text-gray-400 text-justify">
                Are you ready to put your thinking caps on and embark on a
                knowledge-packed journey through the exciting world of Computer
                Science? Join us for the "Geek out trivia" a team event,
                specially designed for school students to test their basic
                knowledge in a variety of streams. Whether you're passionate
                about coding, networking, aptitude, or anything in between, this
                quiz has something for everyone. Our quiz competition is not
                merely a test; it's an exhilarating journey of discovery,
                meticulously crafted to set your passions ablaze and to catalyze
                an expansion of your intellectual horizons across a multitude of
                captivating streams.<br></br>
                <br></br>
                <p class="text-center items-center justify-center font-bold">
                  Team size: 1 member (Fixed)
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
