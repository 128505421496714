import React from "react";
import { BiMailSend } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";

const Contact = () => {
  return (
    <div className="bg-gray-900 p-5 text-center" id="contact">
      <div className="p-3">
        <h3 className="mb-4 text-4xl font-bold tracking-tight text-white">
          Contact Us
        </h3>
      </div>
      <div className="flex justify-center">
        <div className="w-full md:max-w-screen-md p-6 rounded-lg shadow-lg bg-gray-800 text-white">
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <BiMailSend size={32} className="text-primary-300" />
              <div className="text-xl font-semibold">Email:</div>
              <a href="mailto:your@email.com" className="text-lg text-gray-300">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;acmceg2019@
                gmail.com
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <AiOutlinePhone size={32} className="text-primary-300" />
              <div className="text-xl font-semibold">Phone:</div>
              <div className="text-lg text-gray-300">
                Arunachalam - (91) 95515 40001
                <br />
                Rishitha - (91) 90435 02502
                <br />
                Ashwin Muthuraman - (91) 93605 23558
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
