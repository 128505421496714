import React, { useState, useEffect, useCallback } from "react";

function formatNumberWithLeadingZero(number) {
  return number < 10 ? `0${number}` : `${number}`;
}

function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = useCallback(() => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      return {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }

    const days = formatNumberWithLeadingZero(
      Math.floor(difference / (1000 * 60 * 60 * 24))
    );
    const hours = formatNumberWithLeadingZero(
      Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    const minutes = formatNumberWithLeadingZero(
      Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    );
    const seconds = formatNumberWithLeadingZero(
      Math.floor((difference % (1000 * 60)) / 1000)
    );

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [calculateTimeLeft]);

  return (
    <div className="text-white bg-gray-900">
      <h1 className="text-3xl font-bold text-center pb-8 md:pb-8 md:pt-6 sm:pb-8 sm:pt-6">Event Starts In</h1>
      <div className="flex justify-center mt-6 mb-20 flex-wrap">
        <div className="px-11 py-5 border border-white rounded-xl mx-3 mb-3">
          <div className="text-5xl font-bold">{timeLeft.days}</div>
          <div className="text-lg">Days</div>
        </div>
        <div className="px-11 py-5 border border-white rounded-xl mx-3 mb-3">
          <div className="text-5xl font-bold">{timeLeft.hours}</div>
          <div className="text-lg">Hours</div>
        </div>
        <div className="px-10 py-5 border border-white rounded-xl mx-3 mb-3">
        <div className="text-5xl font-bold">{timeLeft.minutes}</div>
          <div className="text-lg">Minutes</div>
        </div>
        <div className="px-10 py-5 border border-white rounded-xl mx-3 mb-3">
          <div className="text-5xl font-bold">{timeLeft.seconds}</div>
          <div className="text-lg">Seconds</div>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
