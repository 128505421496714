import React from "react";
import img1 from "../assets/gallery/1.jpg";
import img2 from "../assets/gallery/2.jpg";
import img3 from "../assets/gallery/3.jpg";
import img4 from "../assets/gallery/4.jpg";
import img5 from "../assets/gallery/5.jpg";
import img6 from "../assets/gallery/6.jpeg";
import img7 from "../assets/gallery/7.jpeg";
import img8 from "../assets/gallery/8.jpeg";
import img9 from "../assets/gallery/9.jpeg";

const Gallery = () => {
  return (
    <div className="bg-gray-900 p-5 text-center" id="gallery">
      <div className="p-6">
        <h3 className="mb-2 text-3xl font-bold tracking-tight text-white">
          Gallery
        </h3>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-6">
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img1}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img2}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img3}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img4}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img5}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img6}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img7}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img8}
            alt=""
          />
        </div>
        <div>
          <img
            class="h-auto max-w-full rounded-lg transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            src={img9}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default Gallery;
