import React from "react";

const About = () => {
  return (
    <div className="bg-gray-900 p-5 text-center" id="about">
      <div className="p-6">
        <h3 className="mb-2 text-3xl font-bold tracking-tight text-white">
          About
        </h3>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-auto max-w-screen-lg p-6 border rounded-lg shadow-lg bg-gray-800 border-gray-700 hover:bg-gray-700 text-center transition-all">
          <h5 className="mb-2 text-xl font-medium tracking-tight text-white">
            ACM CEG
          </h5>
          <p className="font-normal text-gray-400">
            The <b>ACM-CEG Student Chapter</b>, initiated in 2004, aims to instill an
            unwavering enthusiasm for computer science in students. The club
            provides a plethora of networking opportunities and helps to seek
            advice from the top experts in the field. The club has been steadily
            working to inculcate an unalloyed interest for Computer Science in
            students and consequently, stimulating the advancement of computer
            science as a whole. We aim to motivate students to embark on a
            journey of learning and developing the tools of computer science and
            instilling in them a passion for computer science. We serve to clear
            the clutter and provide guidance to help them to achieve their
            goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
