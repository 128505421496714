import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const FAQSection = () => {
  const [isOpen, setIsOpen] = useState(Array(10).fill(false));

  const toggleAccordion = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  const faqData = [
    {
      question: "Is prior computer science knowledge required for the event?",
      answer:
        "It depends on the particular event. Our events broadly fall under two categories - tech and non-tech. Non-tech events like Family Feud and Ta(e)lk it Out! do not require any prior technical CS knowledge. The remaining technical events like Geek Out Trivia, Coding Cauldron, etc. require logical reasoning and basic knowledge of fundamental CSS concepts.",
    },
    {
      question: "Students belonging to which grade are eligible for the event?",
      answer: "Students of 9th to 12th std can participate in the events.",
    },
    {
      question: "Can we participate as a team in the events?",
      answer:
        "Yes, some events allow for team participation (2, 3, 4, or even 5 students!). Check the instructions document to know the exact details of the team size for each event. Instructions document will be shared before the event through WhatsApp groups.",
    },
    {
      question: "What is a rolling event?",
      answer:
        "A Rolling event can be attended at any time during the provided duration. All the mentioned events will be live during the duration mentioned and the students can participate one after the other. For example, if the event Coding Cauldron is scheduled between 10:00 a.m. - 1:00 pm and the time duration of the event is 45 minutes, participants can come anytime between 10 am and 12:15 pm and attempt the event. They will be given 45 mins from the time they start (e.g., if you come at 11 am, you must finish the event by 11:45 am).",
    },
    {
      question: "Can we participate in more than one event?",
      answer:
        "Yes. Participants are free to take part in more than one event as long as their schedules do not clash. Rolling events can be attended at any time within the allotted time frame.",
    },
    {
      question:
        "Do we get certificates and is there prize money for the winners?",
      answer:
        "Yes. Participation certificates will be provided to all the participants. It will be mailed to their email addresses within two weeks following the conclusion of the event. Certificates of appreciation and prize money will be awarded to the winners of the events.",
    },
    {
      question: "Whom to contact if we have any queries about the events?",
      answer:
        "You will be added to WhatsApp groups before the event day, and all your queries will be addressed there. If you have any other concerns/doubts, you can contact the following numbers: Arunachalam - 95515 40001, Rishitha - 90435 02502, Ashwin Muthuraman - 93605 23558, Shivaani - 94442 57125",
    },
    {
      question: "Can we google the answers?",
      answer:
        "Copying or searching for the answers from other resources is strictly prohibited. If found to be copying, the student will be disqualified immediately.",
    },
    {
      question: "Should we register for an event beforehand?",
      answer:
        "Registering beforehand for events is preferable. However, if a student has not registered for a particular event beforehand but wishes to participate, they can register for the event on the spot. NOTE: A few events have individual registration links, which will be shared in advance. Keep a look out for these!",
    },
    {
      question:
        "Can I participate if my school has not registered for the event?",
      answer:
        "Yes, you can register through the Student registration form provided.",
    },
    {
      question: "Will food and accommodation be provided?",
      answer:
        "No, food and accommodation will not be provided to participants. It is imperative that participants bring their own food, water etc.",
    },
    {
      question: "Is it compulsory for staff to accompany the students for the offline event?",
      answer:
        "If more than 15 students are participating from a school, we expect one of the staff to accompany the students for the event.",
    },
  ];

  return (
    <div className="bg-gray-900" id="faq">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div className="grid pt-8 text-left border-t md:gap-16 border-gray-700 md:grid-cols-2">
          <div>
            {faqData
              .slice(0, Math.ceil(faqData.length / 2))
              .map((faq, index) => (
                <div key={index} className="mb-4">
                  <h3 className="flex items-center mb-4 text-lg font-medium text-white">
                    <button
                      className="focus:outline-none"
                      onClick={() => toggleAccordion(index)}
                    >
                      {isOpen[index] ? (
                        <IoIosArrowUp className="w-5 h-5 text-gray-400 mr-2" />
                      ) : (
                        <IoIosArrowDown className="w-5 h-5 text-gray-400 mr-2" />
                      )}
                    </button>
                    {faq.question}
                  </h3>
                  <div
                    className={`text-gray-400 transition-height ease-in-out ${
                      isOpen[index] ? "max-h-full" : "max-h-0 overflow-hidden"
                    }`}
                  >
                    {faq.answer.includes("You will be added to WhatsApp groups before the event day") ? (
                      <>
                        <p>Arunachalam - 95515 40001</p>
                        <p>Rishitha - 90435 02502</p>
                        <p>Ashwin Muthuraman - 93605 23558</p>
                        <p>Shivaani - 94442 57125</p>
                      </>
                    ) : (
                      <p>{faq.answer}</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div>
            {faqData.slice(Math.ceil(faqData.length / 2)).map((faq, index) => (
              <div key={index} className="mb-4">
                <h3 className="flex items-center mb-4 text-lg font-medium text-white">
                  <button
                    className="focus:outline-none"
                    onClick={() =>
                      toggleAccordion(index + Math.ceil(faqData.length / 2))
                    }
                  >
                    {isOpen[index + Math.ceil(faqData.length / 2)] ? (
                      <IoIosArrowUp className="w-5 h-5 text-gray-400 mr-2" />
                    ) : (
                      <IoIosArrowDown className="w-5 h-5text-gray-400 mr-2" />
                    )}
                  </button>
                  {faq.question}
                </h3>
                <div
                  className={`text-gray-400 transition-height ease-in-out ${
                    isOpen[index + Math.ceil(faqData.length / 2)]
                      ? "max-h-full"
                      : "max-h-0 overflow-hidden"
                  }`}
                >
                  {faq.answer.includes("You will be added to WhatsApp groups before the event day") ? (
                    <>
                      <p>You will be added to WhatsApp groups before the event day. If you have any other concerns/doubts, you can contact the following numbers:</p>
                      <p>Arunachalam - 95515 40001</p>
                      <p>Rishitha - 90435 02502</p>
                      <p>Ashwin Muthuraman - 93605 23558</p>
                      <p>Shivaani - 94442 57125</p>
                    </>
                  ) : (
                    <p>{faq.answer}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;