import React, { useState, useEffect } from "react";
import ACM_LOGO from "../assets/acm_logo.png";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import SmoothScroll from "smooth-scroll";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    new SmoothScroll('a[href*="#"]', {
      speed: 800,
    });
  }, []);

  return (
    <nav className="bg-gray-800 border-gray-700 py-3">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 rounded-md">
        <a href="/#" className="flex items-center">
          <img src={ACM_LOGO} className="h-12 mr-3" alt="ACM_CEG Logo" />
        </a>
        <button
          data-collapse-toggle="navbar-solid-bg"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-800 text-gray-400 hover:bg-gray-700 dark:focus:ring-gray-800 transition duration-300"
          aria-controls="navbar-solid-bg"
          aria-expanded={isMobileMenuOpen ? "true" : "false"}
          onClick={toggleMobileMenu}
        >
          <span className="sr-only">Toggle menu</span>
          {isMobileMenuOpen ? (
            <GrFormClose className="w-6 h-6" />
          ) : (
            <HiOutlineMenuAlt3 className="w-6 h-6" />
          )}
        </button>

        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } w-full md:flex md:w-auto transition duration-300`}
          id="navbar-solid-bg"
        >
          <ul className="flex flex-col font-medium mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent bg-gray-800 md:dark:bg-transparent border-gray-700">
            <li>
              <a
                href="/#"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-white hover:text-blue-500 hover:bg-transparent md:hover:bg-transparent transition duration-300"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#events"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-white hover:text-blue-500 hover:bg-transparent md:hover-bg-transparent transition duration-300"
              >
                Events
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-white hover:text-blue-500 hover:bg-transparent md:hover-bg-transparent transition duration-300"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#gallery"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-white hover:text-blue-500 hover:bg-transparent md:hover-bg-transparent transition duration-300"
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-white hover:text-blue-500 hover:bg-transparent md:hover-bg-transparent transition duration-300"
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="#faq"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-white hover:text-blue-500 hover:bg-transparent md:hover-bg-transparent transition duration-300"
              >
                Faq
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
